import {ReactElement, ReactNode, useState} from "react";
import {Address, HandoffOptions, MenuOrder, MenuOrderItem} from "@devour/client";
import OrderHistoryAddressDisplay, {Props as OrderHistoryAddressDisplayProps} from "./OrderHistoryAddressDisplayOld";
import {IoRestaurantOutline} from "react-icons/io5";
import {TiHome} from "react-icons/ti";
import FrameButton from "./buttons/FrameButton";
import OrderHistoryDetailsOrderItem from "./OrderHistoryDetailsOrderItem";
import OrderReceiptModal from "./modals/OrderReceiptModal";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {toggleChatOpen} from "@/redux/meta/metaActions";
import { FaArrowRight } from "react-icons/fa";
import OrderTrackDialog from "./OrderTrackDialog";
import {useRestaurant} from "@/hooks/useRestaurant";
import OrderHistoryDetailsOrderItemOld from "./OrderHistoryDetailsOrderItemOld";

interface Props {
    order: MenuOrder;
}

function OrderHistoryDetailsOld(props: Props): ReactElement {
    const dispatch = useDispatch();
    const isChatOpen = useSelector((store: IStore) => store.metaStore.isChatOpen);
    const [
        showReceiptModal,
        setShowReceiptModal,
    ] = useState(false);

    const [showOrderTrackDialog, setShowOrderTrackDialog] = useState(false);
    const {data: restaurant} = useRestaurant(props.order.business);

    /**
     * Generate the text label for this section based on the order handoff type.
     *
     * @param handoff
     */
    function getLabel(handoff: HandoffOptions): string {
        switch (handoff) {
            case HandoffOptions.DELIVERY:
                return "Delivery details";
            case HandoffOptions.PICKUP:
                return "Pick Up From";
        }
    }

    /**
     * Format address with the components that are present.
     *
     * @param address
     */
    function formatAddressString(address: Address): string {
        let addString = "";
        if (address.line1) {
            addString += address.line1;
        }

        if (address.line2) {
            addString += ` - ${address.line2}`;
        }

        if (address.locality) {
            addString += `, ${address.locality}`;
        }

        if (address.administrativeArea) {
            addString += `, ${address.administrativeArea}`;
        }

        if (address.postalCode) {
            addString += `, ${address.postalCode}`;
        }

        return addString;
    }

    function getAddressDetails(handoff: HandoffOptions): OrderHistoryAddressDisplayProps {
        switch (handoff) {
            case HandoffOptions.DELIVERY:
                return {
                    icon: TiHome,
                    title: formatAddressString(props.order.address),
                    description: props.order.deliveryNotes,
                };
            case HandoffOptions.PICKUP:
                return {
                    icon: IoRestaurantOutline,
                    title: restaurant?.name,
                    description: formatAddressString(restaurant?.address),
                };
        }
    }

    function renderOrderItems(item: MenuOrderItem, i: number): ReactNode {
        return (
            <OrderHistoryDetailsOrderItemOld
                key={`order-item_${i}`}
                item={item}
            />
        );
    }

    /**
     * Show or hide the receipt modal.
     *
     */
    function toggleReceiptModal(): void {
        setShowReceiptModal(s => !s);
    }

    /**
     * Show or hide the help modal.
     *
     */
    function toggleHelpModal(): void {
        dispatch(toggleChatOpen(!isChatOpen));
    }

    if (!props.order) {
        return null;
    }

    return (
        <>
            <OrderReceiptModal
                isOpen={showReceiptModal}
                order={props.order}
                onClose={toggleReceiptModal}
            />

            <OrderTrackDialog
                isOpen={showOrderTrackDialog}
                onClose={() => setShowOrderTrackDialog(false)}
                order={props.order}
            />
            <div className="order-history-details">
                {props.order.handoff === HandoffOptions.DELIVERY && <div className="order-history-details_track-my-order">
                    <FrameButton
                        color="purple"
                        size="large"
                        onClick={() => setShowOrderTrackDialog(true)}
                        rightIcon={FaArrowRight}
                    >
				Track my order
                    </FrameButton>
                    <hr />
                </div>}
                <div className="order-history-details_items">
                    <div className="order-history-details_items_header">
                        <h3 className="order-history-details_items_header_title">
							Your order
                        </h3>

                        <div className="order-history-details_items_header_buttons">
                            {window.Atlas &&
                                <FrameButton
                                    color="gray"
                                    size="narrow"
                                    onClick={toggleHelpModal}
                                >
                                    Help
                                </FrameButton>
                            }

                            <FrameButton
                                color="purple"
                                size="narrow"
                                onClick={toggleReceiptModal}
                            >
								View receipt
                            </FrameButton>
                        </div>
                    </div>

                    <div className="order-history-details_items_list">
                        {props.order.orderItems.map(renderOrderItems)}
                    </div>
                </div>
                {props.order.handoff === HandoffOptions.PICKUP && <div className="order-history-details_pickup">
                    <h3 className="order-history-details_pickup_title">
                        {getLabel(props.order.handoff)}
                    </h3>

                    <div className="order-history-details_pickup_address">
                        <OrderHistoryAddressDisplay
                            {...getAddressDetails(props.order.handoff)}
                            placeId={restaurant?.address?.placeId}
                        />
                    </div>

                </div>
                }
            </div>
        </>
    );
}

export default OrderHistoryDetailsOld;
