import {ReactElement} from "react";
import {IconType} from "react-icons";

export interface Props {
    icon: IconType;
    title: string;
    description?: string;
    placeId?: string;
}

function OrderHistoryAddressDisplayOld(props: Props): ReactElement {

    const Icon = props.icon;

    return (
        <div className="order-history-address-display">
            <div className="order-history-address-display_icon">
                <Icon/>
            </div>

            <div className="order-history-address-display_content">
                <p className="order-history-address-display_content_title">
                    {props.title}
                </p>
                {props.description &&
                <p className="order-history-address-display_content_description">
					    {props.placeId
                        ? <a
                            href={`https://www.google.com/maps/place/?q=place_id:${props.placeId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {props.description}
                        </a>
						 : props.description}
                </p>
                }
            </div>
        </div>
    );
}

export default OrderHistoryAddressDisplayOld;
